import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import ArrowRightIcon from "assets/icons/ArrowRightIcon";
import { getRecentEventDetail } from "api";

function RecentEventDetails() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [recentEventDetails, setRecentEventDetails] = useState(null);

  const getRecentEventDetailData = async (recent_event_id) => {
    const result = await getRecentEventDetail(recent_event_id);
    if (result && result.data && result.data.id) {
      setRecentEventDetails(result.data);
    }
  };

  useEffect(() => {
    if (id) {
      getRecentEventDetailData(id);
    }
  }, [id]);

  return (
    <div>
      <section className="app-main-container">
        <button
          onClick={() => navigate(-1)}
          className="flex gap-2 sm:gap-4 items-center"
        >
          <ArrowRightIcon className="rotate-180 h-3 w-3 fill-black" />
          <span className="text-xs sm:text-sm">Back to previous page</span>
        </button>
      </section>
      <section className="app-main-container flex flex-col-reverse sm:flex-row gap-4 sm:gap-8">
        <div className="space-y-4 sm:space-y-8 w-full">
          <h1 className="text-lg sm:text-2xl font-bold">
            {recentEventDetails?.title}
          </h1>
          <p className="text-base sm:text-xl font-semibold">
            {recentEventDetails?.summary}
          </p>
          <img
            className="w-full"
            src={recentEventDetails?.img_cover}
            alt={recentEventDetails?.title}
          />
          <div
            className="space-y-2 sm:space-y-4 text-sm sm:text-base"
            dangerouslySetInnerHTML={{ __html: recentEventDetails?.content }}
          />
        </div>
        <div>
          <p className="whitespace-nowrap text-xs sm:text-sm">
            {moment(recentEventDetails?.createdAt).format("DD MMMM YYYY")}
          </p>
        </div>
      </section>
      {/* <Newsletter /> */}
    </div>
  );
}

export default RecentEventDetails;
