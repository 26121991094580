import React from "react";

import landingPageImage3 from "assets/images/landing-page-3.jpeg";

import { about } from "utils/constants/data";

function MengenaiYayasanDanSejarahnya() {
  return (
    <div>
      <section>
        <div className="relative">
          <img
            src={landingPageImage3}
            alt="main"
            className="w-full max-h-40 sm:max-h-60 object-cover object-bottom object-center"
          />
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white">
            {/* <div className="app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8">
                <p className="text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start">
                  Next level gaming euophoria
                </p>
              </div> */}
          </div>
        </div>
      </section>

      <section>
        <div className="app-main-container space-y-4">
          <p className="text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start mb-16">
            Mengenai Yayasan dan sejarahnya
          </p>
          <div className="text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto mt-2">
            <>
              <div className="flex justify-center items-center">
                <img
                  src={landingPageImage3}
                  alt="main"
                  className="w-36 h-36 object-cover rounded-full mr-4"
                />
                <div>
                  <p className="mb-2 text-2xl">2013</p>
                  <p className="app-animation-translate-start text-sm text-left">
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis
                    diam condimentum felis convallis.Sed faucibus sagittis a sed
                    commodo fermentum massa.Mauris venenatis lacus at
                    semper.Purus tortor massa ac ultrices gravida in.Viverra
                    ipsum in viverra nisl dignissim gravida vitae.Scelerisque
                    sagittis sem dignissim eleifend ac.
                  </p>
                </div>
              </div>
              <br />
              <div className="flex justify-center items-center">
                <div className="mr-4">
                  <p className="text-end mb-2 text-2xl">2014</p>
                  <p className="app-animation-translate-start text-sm text-right">
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis
                    diam condimentum felis convallis.Sed faucibus sagittis a sed
                    commodo fermentum massa.Mauris venenatis lacus at
                    semper.Purus tortor massa ac ultrices gravida in.Viverra
                    ipsum in viverra nisl dignissim gravida vitae.Scelerisque
                    sagittis sem dignissim eleifend ac.
                  </p>
                </div>
                <img
                  src={landingPageImage3}
                  alt="main"
                  className="w-36 h-36 object-cover rounded-full"
                />
              </div>
              <br />
              <div className="flex justify-center items-center">
                <img
                  src={landingPageImage3}
                  alt="main"
                  className="w-36 h-36 object-cover rounded-full mr-4"
                />
                <div>
                  <p className="mb-2 text-2xl">2015</p>
                  <p className="app-animation-translate-start text-sm text-left">
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis
                    diam condimentum felis convallis.Sed faucibus sagittis a sed
                    commodo fermentum massa.Mauris venenatis lacus at
                    semper.Purus tortor massa ac ultrices gravida in.Viverra
                    ipsum in viverra nisl dignissim gravida vitae.Scelerisque
                    sagittis sem dignissim eleifend ac.
                  </p>
                </div>
              </div>
              <br />
              <div className="flex justify-center items-center">
                <div className="mr-4">
                  <p className="text-end mb-2 text-2xl">2016</p>
                  <p className="app-animation-translate-start text-sm text-right">
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis
                    diam condimentum felis convallis.Sed faucibus sagittis a sed
                    commodo fermentum massa.Mauris venenatis lacus at
                    semper.Purus tortor massa ac ultrices gravida in.Viverra
                    ipsum in viverra nisl dignissim gravida vitae.Scelerisque
                    sagittis sem dignissim eleifend ac.
                  </p>
                </div>
                <img
                  src={landingPageImage3}
                  alt="main"
                  className="w-36 h-36 object-cover rounded-full"
                />
              </div>
            </>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MengenaiYayasanDanSejarahnya;
