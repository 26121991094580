import frameworks from "assets/images/impact/frameworks.png";
import esgInitiatives from "assets/images/impact/esg-initiatives.png";
import portfolioManagement from "assets/images/impact/portfolio-management.png";

import AnalyzeIcon from "assets/icons/AnalyzeIcon";
import BuildIcon from "assets/icons/BuildIcon";
import PlanIcon from "assets/icons/PlanIcon";
import kataSambutanImage from "assets/images/kata-sambutan-image.jpeg";
import Media1 from "assets/images/media-1.jpeg";
import Media2 from "assets/images/media-2.jpeg";
import Media3 from "assets/images/media-3.jpeg";
import Media4 from "assets/images/media-4.jpeg";
import Media5 from "assets/images/media-5.jpeg";
import Media6 from "assets/images/media-6.jpeg";

import mornington from "assets/images/portfolio/mornington.png";
import wollonggong from "assets/images/portfolio/wollonggong.png";

import ImgGallery from "components/layout/ImageGallery";

export const impacts = [
  {
    image: frameworks,
    title: "Framework",
    description: "Lorem ipsum dolor sit amet consectetur.",
    href: "/",
  },
  {
    image: esgInitiatives,
    title: "ESG Initiatives",
    description: "Lorem ipsum dolor sit amet consectetur.",
    href: "/",
  },
  {
    image: portfolioManagement,
    title: "Portfolio Management",
    description: "Lorem ipsum dolor sit amet consectetur.",
    href: "/",
  },
];

const whyUsTopContent = [
  {
    icon: AnalyzeIcon,
    title: "Global E-sport Revenue",
    description:
      "The potential of this nascent industry is huge. Dominated by a young, digital and global audience, the esports industry is fueled with economic opportunities measured by sponsorships, merchandising, ticket sales, advertising and media rights.",
  },
  {
    icon: PlanIcon,
    title: "SEA Esports Market Outlook",
    description:
      "Since 2016, Indonesia placed 2nd on the esports growing market. 2017 was when the esports market in Indonesia grew drastically. Today, Indonesia is one of the leading country for Esports in SEA Region.",
  },
];

const whyUsSecondContent = [
  {
    icon: AnalyzeIcon,
    title: "+75 Million",
    description: "Indonesian are gamers",
  },
  {
    icon: PlanIcon,
    title: "60% of Indonesian",
    description: "own smartphones and 17% of them are active gamers",
  },
  {
    icon: AnalyzeIcon,
    title: "14-35 years old",
    description: "from those are active gamers",
  },
  {
    icon: PlanIcon,
    title: "60% of Indonesian",
    description: "have consumtive behavior towards their hobby",
  },
  {
    icon: AnalyzeIcon,
    title: "8 hours a day",
    description: "Millenials spend their time online",
  },
  {
    icon: PlanIcon,
    title: "6 hours a week",
    description: "Millenials spend their time playing games",
  },
  {
    icon: AnalyzeIcon,
    title: "12 hours a week",
    description:
      "Millenials and other age group halved their TV viewership over the past 7 years",
  },
];

export const whyUs = {
  topContent: (
    <div className="app-main-container grid grid-cols-1 sm:grid-cols-2 gap-8">
      {whyUsTopContent.map((step, i) => {
        const Icon = step.icon;
        return (
          <div key={i} className="space-y-4">
            <div className="space-y-2 app-animation-translate-start">
              <Icon />
              <p className="font-bold uppercase text-sm md:text-base">
                {step.title}
              </p>
            </div>
            <p className="text-sm md:text-base app-animation-translate-start">
              {step.description}
            </p>
          </div>
        );
      })}
    </div>
  ),
  secondContent: (
    <div className="app-main-container grid grid-cols-2 sm:grid-cols-3 gap-8">
      {whyUsSecondContent.map((step, i) => {
        const Icon = step.icon;
        return (
          <div key={i}>
            <div className="space-y-2 app-animation-translate-start">
              <Icon />
              <p className="font-bold uppercase text-sm md:text-base">
                {step.title}
              </p>
            </div>
            <p className="text-sm md:text-base app-animation-translate-start">
              {step.description}
            </p>
          </div>
        );
      })}
    </div>
  ),
  ourServices: [
    {
      id: 1,
      title: "E-Sports consulting",
      link_url: "/esports-consulting",
      image_url:
        "https://images.pexels.com/photos/3165335/pexels-photo-3165335.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      id: 2,
      title: "E-Sports event organizer",
      link_url: "/esports-eo",
      image_url:
        "https://storage.googleapis.com/nizerteklintasbumi/logo/sl-logo.jpeg",
    },
    {
      id: 3,
      title:
        "Production house specializing in OTT content and social media content",
      link_url: "/production-house",
      image_url:
        "https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      id: 4,
      title: "Digital marketing agency",
      link_url: "/digital-marketing",
      image_url:
        "https://images.pexels.com/photos/5678243/pexels-photo-5678243.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      id: 5,
      title: "E-Sports team",
      link_url: "/esports-team",
      image_url:
        "https://assets.skor.id/crop/136x76:1228x690/x/photo/2020/10/05/1364398555.jpg",
    },
  ],
};

export const about = {
  kataSambutan: (
    <>
      <img
        src={kataSambutanImage}
        alt="main"
        className="w-full max-h-50 sm:max-h-80 object-cover object-bottom object-center"
      />
      <br />
      <p className="app-animation-translate-start text-justify">
        Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
        condimentum felis convallis.Sed faucibus sagittis a sed commodo
        fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
        ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
        vitae.Scelerisque sagittis sem dignissim eleifend ac.
      </p>
      <br />
      <p className="app-animation-translate-start text-justify">
        Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
        condimentum felis convallis.Sed faucibus sagittis a sed commodo
        fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
        ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
        vitae.Scelerisque sagittis sem dignissim eleifend ac.
      </p>
      <br />
      <p className="app-animation-translate-start text-justify">
        Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
        condimentum felis convallis.Sed faucibus sagittis a sed commodo
        fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
        ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
        vitae.Scelerisque sagittis sem dignissim eleifend ac.
      </p>
      <br />
      <p className="app-animation-translate-start text-justify">
        Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
        condimentum felis convallis.Sed faucibus sagittis a sed commodo
        fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
        ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
        vitae.Scelerisque sagittis sem dignissim eleifend ac.
      </p>
    </>
  ),
  mengenaiSantoPelindung: (
    <>
      <p className="app-animation-translate-start text-justify">
        Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
        condimentum felis convallis.Sed faucibus sagittis a sed commodo
        fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
        ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
        vitae.Scelerisque sagittis sem dignissim eleifend ac.
      </p>
      <br />
      <p className="app-animation-translate-start text-justify">
        Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
        condimentum felis convallis.Sed faucibus sagittis a sed commodo
        fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
        ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
        vitae.Scelerisque sagittis sem dignissim eleifend ac.
      </p>
      <br />
      <img
        src={kataSambutanImage}
        alt="main"
        className="w-full max-h-50 sm:max-h-80 object-cover object-bottom object-center"
      />
      <br />
      <p className="app-animation-translate-start text-justify">
        Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
        condimentum felis convallis.Sed faucibus sagittis a sed commodo
        fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
        ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
        vitae.Scelerisque sagittis sem dignissim eleifend ac.
      </p>
      <br />
      <p className="app-animation-translate-start text-justify">
        Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
        condimentum felis convallis.Sed faucibus sagittis a sed commodo
        fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
        ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
        vitae.Scelerisque sagittis sem dignissim eleifend ac.
      </p>
    </>
  ),
  description: (
    <>
      <p className="app-animation-translate-start text-justify">
        Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
        condimentum felis convallis.Sed faucibus sagittis a sed commodo
        fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
        ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
        vitae.Scelerisque sagittis sem dignissim eleifend ac.
      </p>
      <br />
      <p className="app-animation-translate-start text-justify">
        Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
        condimentum felis convallis.Sed faucibus sagittis a sed commodo
        fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
        ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
        vitae.Scelerisque sagittis sem dignissim eleifend ac.
      </p>
      <br />
      <p className="app-animation-translate-start text-justify">
        Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
        condimentum felis convallis.Sed faucibus sagittis a sed commodo
        fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
        ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
        vitae.Scelerisque sagittis sem dignissim eleifend ac.
      </p>
      <br />
      <p className="app-animation-translate-start text-justify">
        Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
        condimentum felis convallis.Sed faucibus sagittis a sed commodo
        fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
        ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
        vitae.Scelerisque sagittis sem dignissim eleifend ac.
      </p>
    </>
  ),
  our_role_img: [
    {
      original:
        "https://storage.googleapis.com/nizerteklintasbumi/banner/Setup-Ecosystem_NLB.png",
      thumbnail:
        "https://storage.googleapis.com/nizerteklintasbumi/banner/Setup-Ecosystem_NLB.png",
    },
  ],
};

export const structure = {
  organization_structure_img: [
    {
      original:
        "https://d3n817fwly711g.cloudfront.net/uploads/2011/04/2021-08-18-3.png",
      thumbnail:
        "https://d3n817fwly711g.cloudfront.net/uploads/2011/04/2021-08-18-3.png",
    },
  ],
};

const generalBrandsLogo = [
  {
    id: 1,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/bardi-logo.png",
  },
  {
    id: 2,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/blackshark-logo.webp",
  },
  {
    id: 3,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/boncabe-logo.png",
  },
  {
    id: 4,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/bri-logo.webp",
  },
  {
    id: 5,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/gojek-logo.png",
  },
  {
    id: 6,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/line-logo.png",
  },
  {
    id: 7,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/mandiri-logo.webp",
  },
  {
    id: 8,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/realme-logo.webp",
  },
  {
    id: 9,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/redbull-logo.png",
  },
  {
    id: 10,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/samsung-logo.svg",
  },
  {
    id: 11,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/ticketcom-logo.png",
  },
];

const gamingPeripheralsLogo = [
  {
    id: 1,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/gaming-peripherals/amd-logo.png",
  },
  {
    id: 2,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/gaming-peripherals/aoc-logo.png",
  },
  {
    id: 3,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/gaming-peripherals/asus-logo.png",
  },
  {
    id: 4,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/gaming-peripherals/benq-logo.png",
  },
  {
    id: 5,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/gaming-peripherals/brazen-logo.webp",
  },
  {
    id: 6,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/gaming-peripherals/dxracer-logo.png",
  },
  {
    id: 7,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/gaming-peripherals/legion-logo.webp",
  },
  {
    id: 8,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/gaming-peripherals/logitech-logo.png",
  },
  {
    id: 9,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/gaming-peripherals/predator-logo.png",
  },
  {
    id: 10,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/gaming-peripherals/razer-logo.png",
  },
  {
    id: 11,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/gaming-peripherals/sandisk-logo.png",
  },
  {
    id: 12,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/gaming-peripherals/steelseries-logo.png",
  },
];

const gamePublishersLogo = [
  {
    id: 1,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/gamevil-logo.jpeg",
  },
  {
    id: 2,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/garena-logo.png",
  },
  {
    id: 3,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/igg-logo.png",
  },
  {
    id: 4,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/linepod-logo.png",
  },
  {
    id: 5,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/megaxus-logo.png",
  },
  {
    id: 6,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/mihoyo-logo.svg",
  },
  {
    id: 7,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/moonton-logo.png",
  },
  {
    id: 8,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/riot-logo.png",
  },
  {
    id: 9,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/supercell-logo.png",
  },
  {
    id: 10,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/tencent-logo.webp",
  },
  {
    id: 11,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/vng-logo.png",
  },
];

const ottContentBrand = [
  {
    id: 1,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/firstmedia-logo.png",
  },
  {
    id: 2,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/maxstream-logo.png",
  },
  {
    id: 3,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/nimotv-logo.png",
  },
  {
    id: 4,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/snackvideo-logo.png",
  },
  {
    id: 5,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/useetv-logo.png",
  },
  {
    id: 6,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/vidio-logo.png",
  },
];

const internetProvidersBrand = [
  {
    id: 1,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/internet-providers/axis-logo.png",
  },
  {
    id: 2,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/internet-providers/indihome-logo.png",
  },
  {
    id: 3,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/internet-providers/myrepublic-logo.webp",
  },
  {
    id: 4,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/internet-providers/telkomsel-logo.png",
  },
];

export const esportsConsulting = {
  description: (
    <>
      <p className="app-animation-translate-start">
        A lot of companies are looking to enter the esports industry but they do
        not know how to do so in an effective manner. Unlike traditional sports,
        esports is more complicated because any game can be esports as long as
        there is a competition. Choosing the right esports title combined with a
        concrete marketing plan can give you all the eyeballs you want, the
        engagement you seek and even increasing your sales.
      </p>
      <br />
      <p className="app-animation-translate-start">
        Our team of consultants can provide you with a one stop esports solution
        to meet your needs. From information regarding esports in Indonesia,
        creating a concrete plan for your business to connect with the
        millennial esports market and even implementation.
      </p>
      <br />
      <p className="app-animation-translate-start">
        <b>Business Development Specialist</b>
        <br />
        From our previous experience handling many sectors that willing to go to
        esports industry, we have a lot of partners to work with and we also
        help them to connect each other so they can have mutual campaign that
        benefit for both of them.
      </p>
      <br />
      <p className="app-animation-translate-start">
        Creating new values and pipeline for our clients and continuously
        spreading brand exposure in new creative way with less risk and
        efficient.
      </p>
      <br />
    </>
  ),
  generalBrands: (
    <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
      {generalBrandsLogo &&
        generalBrandsLogo.map((logo, idx) => {
          return (
            <img
              key={idx}
              src={logo && logo.image_url}
              className="h-24 m:w-12 object-contain"
              alt="client-logo"
            />
          );
        })}
      <p>
        <b>and many more...</b>
      </p>
    </div>
  ),
  gamePeripheralBrands: (
    <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
      {gamingPeripheralsLogo &&
        gamingPeripheralsLogo.map((logo, idx) => {
          return (
            <img
              key={idx}
              src={logo && logo.image_url}
              className="h-24 m:w-12 object-contain"
              alt="client-logo"
            />
          );
        })}
      <p>
        <b>and many more...</b>
      </p>
    </div>
  ),
  gamePublishersLogo: (
    <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
      {gamePublishersLogo &&
        gamePublishersLogo.map((logo, idx) => {
          return (
            <img
              key={idx}
              src={logo && logo.image_url}
              className="h-24 m:w-12 object-contain"
              alt="client-logo"
            />
          );
        })}
      <p>
        <b>and many more...</b>
      </p>
    </div>
  ),
  ottContentsLogo: (
    <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
      {ottContentBrand &&
        ottContentBrand.map((logo, idx) => {
          return (
            <img
              key={idx}
              src={logo && logo.image_url}
              className="h-24 m:w-12 object-contain"
              alt="client-logo"
            />
          );
        })}
    </div>
  ),
  internetProvidersLogo: (
    <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
      {internetProvidersBrand &&
        internetProvidersBrand.map((logo, idx) => {
          return (
            <img
              key={idx}
              src={logo && logo.image_url}
              className="h-24 m:w-12 object-contain"
              alt="client-logo"
            />
          );
        })}
    </div>
  ),
};

const esportsEoSecondContent = [
  {
    icon: PlanIcon,
    title: "Concepting",
    description: "Create your event business concept with us",
  },
  {
    icon: PlanIcon,
    title: "Planning",
    description: "Plan with us beforehand, step-by-step basis",
  },
  {
    icon: PlanIcon,
    title: "Digital Assets",
    description: "Create your event assets and promotion material",
  },
  {
    icon: PlanIcon,
    title: "Sponsorship",
    description: "Together we collaborate to support your event",
  },
  {
    icon: PlanIcon,
    title: "Live Streaming",
    description: "Stream live to a broad spectrum of audiences",
  },
  {
    icon: PlanIcon,
    title: "Execution",
    description: "Concept, plan, then execute your important event",
  },
];

export const esportsEo = {
  description: (
    <div className="app-animation-translate-start">
      <div className="py-10 flex justify-center items-center">
        <img
          src="https://storage.googleapis.com/nizerteklintasbumi/logo/sl-logo.jpeg"
          alt="supreme-league-logo"
          className="object-contain"
        />
      </div>
    </div>
  ),
  secondContent: (
    <div className="app-main-container grid grid-cols-2 sm:grid-cols-3 gap-8">
      {esportsEoSecondContent.map((step, i) => {
        const Icon = step.icon;
        return (
          <div key={i}>
            <div className="space-y-2 app-animation-translate-start">
              <Icon />
              <p className="font-bold uppercase text-sm md:text-base">
                {step.title}
              </p>
            </div>
            <p className="text-sm md:text-base app-animation-translate-start">
              {step.description}
            </p>
          </div>
        );
      })}
    </div>
  ),
};

const phClientsLogo = [
  {
    id: 1,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/firstmedia-logo.png",
  },
  {
    id: 2,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/vidio-logo.png",
  },
  {
    id: 3,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/maxstream-logo.png",
  },
  {
    id: 4,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/tencent-logo.webp",
  },
  {
    id: 5,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/supercell-logo.png",
  },
  {
    id: 6,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/nimotv-logo.png",
  },
  {
    id: 7,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/internet-providers/telkomsel-logo.png",
  },
  {
    id: 8,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/redbull-logo.png",
  },
];

const phVideoBanner = [
  {
    id: 1,
    title: "Love in Game",
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/banner/love-in-game-banner.png",
    link_url: "https://maxstream.tv/movies/0_xp5n50sz",
  },
];

const phYoutuveVideoLinks = [
  {
    id: 1,
    title: "PUBG Mobile Jawara Community",
    video_url: "https://www.youtube.com/embed/iM--q-HAIUI?si=lIrzxVxp2F229AE_",
  },
  {
    id: 2,
    title: "AOC Valentine Campaign",
    video_url: "https://www.youtube.com/embed/ZT2XhYtwrYs?si=P_8tWcb332nFepAE",
  },
  {
    id: 3,
    title: "AGON by AOC Campaign",
    video_url: "https://www.youtube.com/embed/V_Nrj3FSKnw?si=WjzvnV5_BpVfm8RA",
  },
];

const phGdriveVideoLinks = [
  {
    id: 1,
    title: "E-Sports Time",
    video_url:
      "https://drive.google.com/file/d/1rgdpg_yzKCPxw98SFB5Yk4fEJ-XkERQG/preview",
  },
  {
    id: 2,
    title: "Mabar Yuk!",
    video_url:
      "https://drive.google.com/file/d/1c__-g-W5yImfNGURE3Eaaqu162yFQ9hh/preview",
  },
  {
    id: 3,
    title: "Forgotten Path",
    video_url:
      "https://drive.google.com/file/d/1OjPGg0Vd5AZW3WE6-ORaHOUDoAwbWP9X/preview",
  },
];

const phSecondContent = [
  {
    icon: PlanIcon,
    title: "Digital Assets",
    description:
      "Unlock the power of your business with Digital Assets, the key to a dynamic and competitive online presence",
  },
  {
    icon: PlanIcon,
    title: "Observing Game",
    description:
      "Elevate your gaming experience with our revolutionary Observing Game feature, where every move counts and every moment is extraordinary.",
  },
  {
    icon: PlanIcon,
    title: "Multi Livecam Production",
    description:
      "Where multiple angles come together for an unforgettable live-streaming spectacle!",
  },
  {
    icon: PlanIcon,
    title: "Stream Talents",
    description:
      "Discover the next big thing in entertainment with our Stream Talents feature, your gateway to a world of captivating, fresh, and charismatic content creators.",
  },
  {
    icon: PlanIcon,
    title: "Multi Channel Live Streaming",
    description:
      "Elevate your reach and engagement with our Multi Channel Live Streaming feature, your passport to simultaneous, impactful content delivery across multiple platforms.",
  },
];

const phStudioImages = [
  {
    original:
      "https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    thumbnail:
      "https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    original:
      "https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    thumbnail:
      "https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    original:
      "https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    thumbnail:
      "https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
];

const phStudio2Images = [
  {
    original:
      "https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    thumbnail:
      "https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    original:
      "https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    thumbnail:
      "https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    original:
      "https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    thumbnail:
      "https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
];

const phDigitalAssetImages = [
  {
    original:
      "https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    thumbnail:
      "https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    original:
      "https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    thumbnail:
      "https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    original:
      "https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    thumbnail:
      "https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
];

export const productionHouse = {
  description: (
    <>
      <p className="app-animation-translate-start">
        Our Production house is currently responsible for filling in 45 episodes
        a month (25 Hours of content) one of the biggest cable tv in Indonesia.
        We call our programs “Esportsainment”, since to us esports is not just
        about tournaments but also entertainment.
      </p>
      <br />
      <p className="app-animation-translate-start">
        We provide our clients with the opportunity to have their brands
        featured in our programs (from soft selling to hard selling), We also
        provide them the opportunity to create high quality content for their
        own individual channel (Social media or youtube Channel)
      </p>
    </>
  ),
  secondContent: (
    <div className="app-main-container grid grid-cols-2 sm:grid-cols-3 gap-8">
      {phSecondContent.map((step, i) => {
        const Icon = step.icon;
        return (
          <div key={i}>
            <div className="space-y-2 app-animation-translate-start">
              <Icon />
              <p className="font-bold uppercase text-sm md:text-base">
                {step.title}
              </p>
            </div>
            <p className="text-sm md:text-base app-animation-translate-start">
              {step.description}
            </p>
          </div>
        );
      })}
    </div>
  ),
  generalBrands: (
    <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
      {phClientsLogo &&
        phClientsLogo.map((logo, idx) => {
          return (
            <img
              key={idx}
              src={logo && logo.image_url}
              className="h-24 m:w-12 object-contain"
              alt="client-logo"
            />
          );
        })}
    </div>
  ),
  cableTvBrands: (
    <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
      {ottContentBrand &&
        ottContentBrand.map((logo, idx) => {
          return (
            <img
              key={idx}
              src={logo && logo.image_url}
              className="h-24 m:w-12 object-contain"
              alt="client-logo"
            />
          );
        })}
    </div>
  ),
  showreels: (
    <div className="grid grid-cols-2 sm:grid-cols-3 gap-8">
      {phVideoBanner &&
        phVideoBanner.map((image, idx) => {
          return (
            <a
              key={idx}
              href={image.link_url}
              target="_blank"
              rel="noreferrer"
              className="app-animation-translate-start"
            >
              <div className="w-full h-32 sm:h-48 bg-black">
                <img
                  src={image.image_url}
                  alt="video"
                  className="w-full h-full object-contain"
                />
              </div>
              <p className="text-sm sm:text-base text-center text-app-blue-3 font-semibold pt-2">
                {image.title}
              </p>
            </a>
          );
        })}
      {phYoutuveVideoLinks &&
        phYoutuveVideoLinks.map((video, index) => {
          return (
            <div key={index} className="app-animation-translate-start">
              <div className="w-full h-32 sm:h-48">
                <iframe
                  width="100%"
                  height="100%"
                  src={video.video_url}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="true"
                ></iframe>
              </div>
              <p className="text-sm sm:text-base text-center text-app-blue-3 font-semibold pt-2">
                {video.title}
              </p>
            </div>
          );
        })}
      {phGdriveVideoLinks &&
        phGdriveVideoLinks.map((video, i) => {
          return (
            <div key={i} className="app-animation-translate-start">
              <div className="w-full h-32 sm:h-48 app-animation-translate-start">
                <iframe
                  title="video"
                  width="100%"
                  height="100%"
                  src={video.video_url}
                  allow="autoplay"
                  allowfullscreen="true"
                ></iframe>
              </div>
              <p className="text-sm sm:text-base text-center text-app-blue-3 font-semibold pt-2">
                {video.title}
              </p>
            </div>
          );
        })}
    </div>
  ),
  studio: (
    <>
      <p className="app-animation-translate-start">
        We have two different setup in our studio to ensure we meet the highest
        broadcasting standard. All our studios are fitted with State-of-the-art
        lighting, camera and broadcast equipment.
      </p>
      <br />
      <p className="app-animation-translate-start">
        From product placement to logo placement we will ensure the best
        placements so that our viewers will be aware of your needs.
      </p>
      <br />
      <p className="app-animation-translate-start">
        <b>3D Virtual Studio</b>
        <br />
        <div className="py-4">
          <ImgGallery images={phStudioImages} />
        </div>
      </p>
      <br />
      <p className="app-animation-translate-start">
        <b>3D Virtual Studio - home cast during pandemic</b>
        <br />
        <div className="py-4">
          <ImgGallery images={phStudio2Images} />
        </div>
      </p>
      <br />
      <p className="app-animation-translate-start">
        <b>Digital Assets</b>
        <br />
        <div className="py-4">
          <ImgGallery images={phDigitalAssetImages} />
        </div>
      </p>
    </>
  ),
};

const publishingPrClients = [
  {
    id: 1,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/firstmedia-logo.png",
  },
  {
    id: 2,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/vidio-logo.png",
  },
  {
    id: 3,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/maxstream-logo.png",
  },
  {
    id: 4,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/tencent-logo.webp",
  },
  {
    id: 5,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/supercell-logo.png",
  },
  {
    id: 6,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/nimotv-logo.png",
  },
  {
    id: 7,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/internet-providers/telkomsel-logo.png",
  },
  {
    id: 8,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/redbull-logo.png",
  },
];

const technoMediaPartnerLogo = [
  {
    id: 1,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/carisinyal.png",
  },
  {
    id: 2,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/dormatekno.png",
  },
  {
    id: 3,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/droidlime.png",
  },
  {
    id: 4,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/duerclockingid.png",
  },
  {
    id: 5,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/gadetapa.png",
  },
  {
    id: 6,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/gadgetdiva.png",
  },
  {
    id: 7,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/gadgetsquad.png",
  },
  {
    id: 8,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/gadgettren.png",
  },
  {
    id: 9,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/gawaimikro.png",
  },
  {
    id: 10,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/gayafone.png",
  },
  {
    id: 11,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/gizmologi.png",
  },
  {
    id: 12,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/hardwareholic.png",
  },
  {
    id: 13,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/hittekno.png",
  },
  {
    id: 14,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/infokom.png",
  },
  {
    id: 15,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/inilahcom.png",
  },
  {
    id: 16,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/jagatreview.png",
  },
  {
    id: 17,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/kincir.png",
  },
  {
    id: 18,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/mbittekno.png",
  },
  {
    id: 19,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/nextren.png",
  },
  {
    id: 20,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/pricebook.png",
  },
  {
    id: 21,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/pulsa.png",
  },
  {
    id: 22,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/review.png",
  },
  {
    id: 23,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/selular.png",
  },
  {
    id: 24,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/skor.png",
  },
  {
    id: 25,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/techbiz.png",
  },
  {
    id: 26,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/techdaily.png",
  },
  {
    id: 27,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/technologue.png",
  },
  {
    id: 28,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/tekid.png",
  },
  {
    id: 29,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/teknogav.png",
  },
  {
    id: 30,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/telkoid.png",
  },
  {
    id: 31,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/trenteknologi.png",
  },
  {
    id: 32,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/yangcanggih.png",
  },
  {
    id: 33,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/youngster.png",
  },
];

const gamingMediaPartnerLogo = [
  {
    id: 1,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/d.png",
  },
  {
    id: 2,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/duniagames.png",
  },
  {
    id: 3,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/duniaku.png",
  },
  {
    id: 4,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/esportsid.png",
  },
  {
    id: 5,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/esportsku.png",
  },
  {
    id: 6,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/gamebrott.png",
  },
  {
    id: 7,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/gamedaim.png",
  },
  {
    id: 8,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/gamefever.png",
  },
  {
    id: 9,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/gameholic.png",
  },
  {
    id: 10,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/ggwpid.png",
  },
  {
    id: 11,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/gridgames.png",
  },
  {
    id: 12,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/gudanggaming.png",
  },
  {
    id: 13,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/hybrid.png",
  },
  {
    id: 14,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/indoesports.png",
  },
  {
    id: 15,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/indogames.png",
  },
  {
    id: 16,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/jagatplay.png",
  },
  {
    id: 17,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/kabargames.png",
  },
  {
    id: 18,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/kaorinusantara.png",
  },
  {
    id: 19,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/kincir.png",
  },
  {
    id: 20,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/kotakgame.png",
  },
  {
    id: 21,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/mastergame.png",
  },
  {
    id: 22,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/onesports.png",
  },
  {
    id: 23,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/revivaltv.png",
  },
  {
    id: 24,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/skorid.png",
  },
  {
    id: 25,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/skyegrid.png",
  },
  {
    id: 26,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/yodu.png",
  },
];

const generalMediaPartnerLogo = [
  {
    id: 1,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/akuratco.png",
  },
  {
    id: 2,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/alix.png",
  },
  {
    id: 3,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/antv.svg.png",
  },
  {
    id: 4,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/beritasatu.png",
  },
  {
    id: 5,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/bisnisindonesia.png",
  },
  {
    id: 6,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/canggihid.png",
  },
  {
    id: 7,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/cnn.svg.png",
  },
  {
    id: 8,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/dailysocialid.png",
  },
  {
    id: 9,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/detikcom.png",
  },
  {
    id: 10,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/jawapos.png",
  },
  {
    id: 11,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/jpnn.png",
  },
  {
    id: 12,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/kapanlagi.svg.png",
  },
  {
    id: 13,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/kompas.png",
  },
  {
    id: 14,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/kompascom.png",
  },
  {
    id: 15,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/kompastekno.png",
  },
  {
    id: 16,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/kumparan.png",
  },
  {
    id: 17,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/liputan6.svg.png",
  },
  {
    id: 18,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/marketeers.png",
  },
  {
    id: 19,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/merahputih.png",
  },
  {
    id: 20,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/merdekacom.png",
  },
  {
    id: 21,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/okezone.png",
  },
  {
    id: 22,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/rakyatmerdeka.png",
  },
  {
    id: 23,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/republika.png",
  },
  {
    id: 24,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/sindonews.png",
  },
  {
    id: 25,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/suaracom.png",
  },
  {
    id: 26,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/tagarid.png",
  },
  {
    id: 27,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/tempo.png",
  },
  {
    id: 28,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/tvone.png",
  },
  {
    id: 29,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/uzone.png",
  },
  {
    id: 30,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/viva.png",
  },
  {
    id: 31,
    image_url:
      "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/wartaekonomi.png",
  },
];

const promotionalImages = [
  {
    original:
      "https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    thumbnail:
      "https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    original:
      "https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    thumbnail:
      "https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    original:
      "https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    thumbnail:
      "https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
];

export const publishingPr = {
  description: (
    <>
      <p className="app-animation-translate-start">
        We believe that a good game also need a good PR media management to make
        everyone anticiapte the intial release.
      </p>
      <br />
      <p className="app-animation-translate-start">
        With our experience, we ensure that your game will be known by public,
        We also provide the PR Timeline, Media Link, and Copywriting for the
        press release.
      </p>
    </>
  ),
  generalBrands: (
    <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
      {publishingPrClients &&
        publishingPrClients.map((logo, idx) => {
          return (
            <img
              key={idx}
              src={logo && logo.image_url}
              className="h-24 m:w-12 object-contain"
              alt="client-logo"
            />
          );
        })}
    </div>
  ),
  technoMediaBrands: (
    <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
      {technoMediaPartnerLogo &&
        technoMediaPartnerLogo.map((logo, idx) => {
          return (
            <img
              key={idx}
              src={logo && logo.image_url}
              className="h-24 m:w-12 object-contain"
              alt="client-logo"
            />
          );
        })}
    </div>
  ),
  gamingMediaBrands: (
    <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
      {gamingMediaPartnerLogo &&
        gamingMediaPartnerLogo.map((logo, idx) => {
          return (
            <img
              key={idx}
              src={logo && logo.image_url}
              className="h-24 m:w-12 object-contain"
              alt="client-logo"
            />
          );
        })}
    </div>
  ),
  generalMediaBrands: (
    <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
      {generalMediaPartnerLogo &&
        generalMediaPartnerLogo.map((logo, idx) => {
          return (
            <img
              key={idx}
              src={logo && logo.image_url}
              className="h-24 m:w-12 object-contain"
              alt="client-logo"
            />
          );
        })}
    </div>
  ),
  promotionalItems: (
    <div className="py-4">
      <ImgGallery images={promotionalImages} />
    </div>
  ),
};

const eventMarketingTopContent = [
  {
    icon: AnalyzeIcon,
    title: "Hard to reach, rewarding at the end",
    description:
      "Millennials and Gen Z are known as the “unreachable” generation and we know how hard it can be to reach them.",
  },
  {
    icon: PlanIcon,
    title: "100% success rate",
    description:
      "However, Supreme League has been able to maintain a 100% success rate in reaching millennials and gen Z to come to our events.",
  },
  {
    icon: PlanIcon,
    title: "Tailored marketing strategies",
    description:
      "Ensuring our success rate for online and offline client's marketing",
  },
  {
    icon: PlanIcon,
    title: "On-site activations",
    description:
      "We have our team rolling door-to-door places-to-places to boost our success rate",
  },
];

const marketingCatImages = [
  {
    original:
      "https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    thumbnail:
      "https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    original:
      "https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    thumbnail:
      "https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    original:
      "https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    thumbnail:
      "https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
];

export const eventMarketing = {
  topContent: (
    <div className="app-main-container grid grid-cols-1 sm:grid-cols-2 gap-8">
      {eventMarketingTopContent.map((step, i) => {
        const Icon = step.icon;
        return (
          <div key={i} className="space-y-4">
            <div className="space-y-2 app-animation-translate-start">
              <Icon />
              <p className="font-bold uppercase text-sm md:text-base">
                {step.title}
              </p>
            </div>
            <p className="text-sm md:text-base app-animation-translate-start">
              {step.description}
            </p>
          </div>
        );
      })}
    </div>
  ),
  marketingCatalogue: (
    <div className="py-4">
      <ImgGallery images={marketingCatImages} />
    </div>
  ),
};

const digitalMarketingTopContent = [
  {
    icon: AnalyzeIcon,
    title: "It's all about relationship building",
    description:
      "Creating awareness and enganging users are the two most important facets of digital marketing. We help our clients connect with millennials and future generations. We help build a deep relationship with your potential user.",
  },
  {
    icon: PlanIcon,
    title: "Right user, right content",
    description:
      "We are experts in content creation and managing all the different social media platforms. We can also manage all your online advertising to effectively connect with your potential customers.",
  },
  {
    icon: PlanIcon,
    title: "Out-of-Home advertising",
    description:
      "Curating the advertising campaign to ensure that you get the maximum awareness by finding strategic locations and accurate call-to-action.",
  },
];

const dMarketingCatImages = [
  {
    description: "Short decsription for Media 1",
    original: Media1,
    thumbnail: Media1,
  },
  {
    description: "Short decsription for Media 2",
    original: Media2,
    thumbnail: Media2,
  },
  {
    description: "Short decsription for Media 3",
    original: Media3,
    thumbnail: Media3,
  },
  {
    description: "Short decsription for Media 4",
    original: Media4,
    thumbnail: Media4,
  },
  {
    description: "Short decsription for Media 5",
    original: Media5,
    thumbnail: Media5,
  },
  {
    description: "Short decsription for Media 6",
    original: Media6,
    thumbnail: Media6,
  },
];

export const digitalMarketing = {
  topContent: (
    <div className="app-main-container grid grid-cols-1 sm:grid-cols-2 gap-8">
      {digitalMarketingTopContent.map((step, i) => {
        const Icon = step.icon;
        return (
          <div key={i} className="space-y-4">
            <div className="space-y-2 app-animation-translate-start">
              <Icon />
              <p className="font-bold uppercase text-sm md:text-base">
                {step.title}
              </p>
            </div>
            <p className="text-sm md:text-base app-animation-translate-start">
              {step.description}
            </p>
          </div>
        );
      })}
    </div>
  ),
  marketingCatalogue: (
    <div className="py-4">
      <ImgGallery images={dMarketingCatImages} />
    </div>
  ),
};

const aeImages = [
  {
    original:
      "https://cdn.vcgamers.com/news/wp-content/uploads/2023/02/Logo-Alterego-1.jpg",
    thumbnail:
      "https://cdn.vcgamers.com/news/wp-content/uploads/2023/02/Logo-Alterego-1.jpg",
    description: "Alter Ego 1",
  },
  {
    original:
      "https://awsimages.detik.net.id/community/media/visual/2022/08/03/ini-dia-roster-baru-alter-ego-esports-di-mpl-id-s10_169.png?w=1200",
    thumbnail:
      "https://awsimages.detik.net.id/community/media/visual/2022/08/03/ini-dia-roster-baru-alter-ego-esports-di-mpl-id-s10_169.png?w=1200",
    description: "Alter Ego 2",
  },
  {
    original:
      "https://d1tgyzt3mf06m9.cloudfront.net/v3-staging/2023/08/230827-PMSL-SEA-Fall-2023-Alter-Ego-Juara.png",
    thumbnail:
      "https://d1tgyzt3mf06m9.cloudfront.net/v3-staging/2023/08/230827-PMSL-SEA-Fall-2023-Alter-Ego-Juara.png",
    description: "Alter Ego 3",
  },
];

export const esportsTeam = {
  aeCatalogue: (
    <div className="py-4">
      <ImgGallery images={aeImages} />
    </div>
  ),
};

export const people = [
  {
    image:
      "https://storage.googleapis.com/nizerteklintasbumi/dummy/Dummy%20profile.png",
    name: "Rheinhard Brain",
    position: "Director",
  },
  {
    image:
      "https://storage.googleapis.com/nizerteklintasbumi/dummy/Dummy%20profile.png",
    name: "Bobby Luis",
    position: "Director",
  },
  {
    image:
      "https://storage.googleapis.com/nizerteklintasbumi/dummy/Dummy%20profile.png",
    name: "Indra Hadiyanto",
    position: "Director",
  },
];

export const portfolio = [
  {
    id: 1,
    image: mornington,
    name: "Mornington peninsula villas",
    description: (
      <>
        <p className="app-animation-translate-start">
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
          condimentum felis convallis.Sed faucibus sagittis a sed commodo
          fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
          ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
          vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className="app-animation-translate-start">
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
          condimentum felis convallis.Sed faucibus sagittis a sed commodo
          fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
          ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
          vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className="app-animation-translate-start">
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
          condimentum felis convallis.Sed faucibus sagittis a sed commodo
          fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
          ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
          vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className="app-animation-translate-start">
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
          condimentum felis convallis.Sed faucibus sagittis a sed commodo
          fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
          ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
          vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
      </>
    ),
    section1Photos: [mornington, mornington],
    section2Photos: [mornington, mornington],
    tagline:
      "Modern living reimagined. Bringing the balance for luxury and minimalist.",
  },
  {
    id: 2,
    image: wollonggong,
    name: "Wollonggong private residence",
    description: (
      <>
        <p className="app-animation-translate-start">
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
          condimentum felis convallis.Sed faucibus sagittis a sed commodo
          fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
          ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
          vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className="app-animation-translate-start">
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
          condimentum felis convallis.Sed faucibus sagittis a sed commodo
          fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
          ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
          vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className="app-animation-translate-start">
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
          condimentum felis convallis.Sed faucibus sagittis a sed commodo
          fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
          ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
          vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className="app-animation-translate-start">
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
          condimentum felis convallis.Sed faucibus sagittis a sed commodo
          fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
          ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
          vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
      </>
    ),
    section1Photos: [wollonggong, wollonggong],
    section2Photos: [wollonggong, wollonggong],
    tagline:
      "Modern living reimagined. Bringing the balance for luxury and minimalist.",
  },
  {
    id: 3,
    image: mornington,
    name: "Mornington peninsula villas",
    description: (
      <>
        <p className="app-animation-translate-start">
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
          condimentum felis convallis.Sed faucibus sagittis a sed commodo
          fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
          ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
          vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className="app-animation-translate-start">
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
          condimentum felis convallis.Sed faucibus sagittis a sed commodo
          fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
          ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
          vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className="app-animation-translate-start">
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
          condimentum felis convallis.Sed faucibus sagittis a sed commodo
          fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
          ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
          vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className="app-animation-translate-start">
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
          condimentum felis convallis.Sed faucibus sagittis a sed commodo
          fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
          ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
          vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
      </>
    ),
    section1Photos: [mornington, mornington],
    section2Photos: [mornington, mornington],
    tagline:
      "Modern living reimagined. Bringing the balance for luxury and minimalist.",
  },
  {
    id: 4,
    image: wollonggong,
    name: "Wollonggong private residence",
    description: (
      <>
        <p className="app-animation-translate-start">
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
          condimentum felis convallis.Sed faucibus sagittis a sed commodo
          fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
          ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
          vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className="app-animation-translate-start">
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
          condimentum felis convallis.Sed faucibus sagittis a sed commodo
          fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
          ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
          vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className="app-animation-translate-start">
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
          condimentum felis convallis.Sed faucibus sagittis a sed commodo
          fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
          ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
          vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className="app-animation-translate-start">
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
          condimentum felis convallis.Sed faucibus sagittis a sed commodo
          fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac
          ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida
          vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
      </>
    ),
    section1Photos: [wollonggong, wollonggong],
    section2Photos: [wollonggong, wollonggong],
    tagline:
      "Modern living reimagined. Bringing the balance for luxury and minimalist.",
  },
];

export const workSteps = [
  {
    icon: AnalyzeIcon,
    title: "Analyze",
    description:
      "Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper.",
  },
  {
    icon: PlanIcon,
    title: "Plan",
    description:
      "Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper.",
  },
  {
    icon: BuildIcon,
    title: "Build",
    description:
      "Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper.",
  },
];

export const posts = [
  {
    image: mornington,
    title: "Mornington peninsula villas",
    description:
      "Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. ",
  },
  {
    image: mornington,
    title: "Mornington peninsula villas",
    description:
      "Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. ",
  },
  {
    image: mornington,
    title: "Mornington peninsula villas",
    description:
      "Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. ",
  },
];

export const terms = {
  updatedAt: "08 August 2023",
  content: [
    {
      title: "Modification of Content",
      body: "Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.",
    },
    {
      title: "Data Security",
      body: "Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.",
    },
    {
      title: "Ownership",
      body: "Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.",
    },
    {
      title: "Material Interests",
      body: "Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac. Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac. Lorem ipsum dolor sit amet consectetur.",
    },
    {
      title: "Device Use",
      body: "Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.",
    },
  ],
};

export const home = {
  description: (
    <>
      <p className="app-animation-translate-start">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      <br />
      <p className="app-animation-translate-start">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>
      <br />
      <p className="app-animation-translate-start">
        <b>Business Development Specialist</b>
        <br />
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>
      <br />
      <p className="app-animation-translate-start">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </p>
      <br />
    </>
  ),
  generalBrands: (
    <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
      {generalBrandsLogo &&
        generalBrandsLogo.map((logo, idx) => {
          return (
            <img
              key={idx}
              src={logo && logo.image_url}
              className="h-24 m:w-12 object-contain"
              alt="client-logo"
            />
          );
        })}
      <p>
        <b>and many more...</b>
      </p>
    </div>
  ),
  gamePeripheralBrands: (
    <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
      {gamingPeripheralsLogo &&
        gamingPeripheralsLogo.map((logo, idx) => {
          return (
            <img
              key={idx}
              src={logo && logo.image_url}
              className="h-24 m:w-12 object-contain"
              alt="client-logo"
            />
          );
        })}
      <p>
        <b>and many more...</b>
      </p>
    </div>
  ),
  gamePublishersLogo: (
    <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
      {gamePublishersLogo &&
        gamePublishersLogo.map((logo, idx) => {
          return (
            <img
              key={idx}
              src={logo && logo.image_url}
              className="h-24 m:w-12 object-contain"
              alt="client-logo"
            />
          );
        })}
      <p>
        <b>and many more...</b>
      </p>
    </div>
  ),
  ottContentsLogo: (
    <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
      {ottContentBrand &&
        ottContentBrand.map((logo, idx) => {
          return (
            <img
              key={idx}
              src={logo && logo.image_url}
              className="h-24 m:w-12 object-contain"
              alt="client-logo"
            />
          );
        })}
    </div>
  ),
  internetProvidersLogo: (
    <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
      {internetProvidersBrand &&
        internetProvidersBrand.map((logo, idx) => {
          return (
            <img
              key={idx}
              src={logo && logo.image_url}
              className="h-24 m:w-12 object-contain"
              alt="client-logo"
            />
          );
        })}
    </div>
  ),
};
