import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getUpdatedArticleDetail } from "api";

function UpdatedArticleDetails() {
  const { id } = useParams();

  const [updatedArticleDetails, setUpdatedArticleDetails] = useState(null);

  const getUpdatedArticleDetailData = async (recent_event_id) => {
    const result = await getUpdatedArticleDetail(recent_event_id);
    if (result && result.data && result.data.id) {
      setUpdatedArticleDetails(result.data);
    }
  };

  useEffect(() => {
    if (id) {
      getUpdatedArticleDetailData(id);
    }
  }, [id]);

  return (
    <div className="space-y-4">
      <section>
        <img
          className="w-full max-h-[65vh] object-contain border"
          src={updatedArticleDetails?.img_cover}
          alt={updatedArticleDetails?.title}
        />
      </section>

      <section>
        <div className="app-main-container space-y-4">
          <p className="text-lg sm:text-xl font-bold ">
            {updatedArticleDetails?.title}
          </p>
          <div className="text-sm sm:text-base font-medium">
            {updatedArticleDetails?.summary}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: updatedArticleDetails?.content }}
          />
        </div>
      </section>
      {/* <Newsletter /> */}
    </div>
  );
}

export default UpdatedArticleDetails;
