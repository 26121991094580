import React from "react";

import landingPageImage3 from "assets/images/landing-page-3.jpeg";

import { about } from "utils/constants/data";

function KataSambutan() {
  return (
    <div>
      <section>
        <div className="relative">
          <img
            src={landingPageImage3}
            alt="main"
            className="w-full max-h-40 sm:max-h-60 object-cover object-bottom object-center"
          />
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white">
            {/* <div className="app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8">
                <p className="text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start">
                  Next level gaming euophoria
                </p>
              </div> */}
          </div>
        </div>
      </section>

      <section>
        <div className="app-main-container space-y-4 flex flex-col items-center justify-center sm:justify-start">
          <p className="text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start">
            Kata sambutan
          </p>
          <div className="text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto">
            {about.kataSambutan}
          </div>
        </div>
      </section>
    </div>
  );
}

export default KataSambutan;
