const {
  CLIENT_PROD_HOST,
  CLIENT_STAGING_HOST,
  SERVER_STAGING_URL,
  SERVER_DEV_URL,
  SERVER_PROD_URL
} = require('../../config')

export const IS_STAGING = window.location.host === CLIENT_STAGING_HOST
export const IS_PRODUCTION = window.location.host === CLIENT_PROD_HOST
export const DISABLE_FETCH = IS_STAGING || IS_PRODUCTION

export let API_BASE_URL = ''
if (IS_STAGING) {
  API_BASE_URL = SERVER_STAGING_URL
} else if (IS_PRODUCTION) {
  API_BASE_URL = SERVER_PROD_URL
} else {
  API_BASE_URL = SERVER_DEV_URL
}