import React, { useCallback, useEffect, useRef, useState } from "react";

import logo from "assets/images/Cloudxier.png";
import { navigation } from "utils/constants/index";

function Navbar({ openMenu }) {
  const dropdownRef = useRef(null);
  const navbarRef = useRef(null);

  const [dropdownOpen, setDropdownOpen] = useState(null);

  const handleMouseOver = useCallback(
    (e) => {
      if (
        dropdownOpen &&
        !dropdownRef?.current?.contains(e.target) &&
        !navbarRef?.current?.contains(e.target)
      ) {
        setDropdownOpen(null);
      }
    },
    [dropdownOpen, setDropdownOpen]
  );

  useEffect(() => {
    window.addEventListener("mouseover", handleMouseOver);
    return () => {
      window.removeEventListener("mouseover", handleMouseOver);
    };
  }, [handleMouseOver]);

  return (
    <>
      <div className={`sticky top-0 shadow-md transition-all z-40 bg-app-gold`}>
        <div
          ref={navbarRef}
          className={`app-nav-container flex justify-between items-center`}
        >
          <div>
            <a href="/">
              <img src={logo} alt="logo" className="h-6 md:h-8" />
            </a>
          </div>
          <div className={`hidden sm:flex md:space-x-12 text-white font-bold`}>
            <ul className="flex items-center space-x-4 md:space-x-6 sm:text-sm md:text-base">
              {navigation.map((nav) => (
                <li
                  key={nav?.id}
                  onMouseOver={() => setDropdownOpen(nav?.id)}
                  className="cursor-pointer"
                >
                  <a className="hover:border-b pb-1" href={nav.href}>
                    {nav.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <button
            className="text-white text-sm sm:hidden active:border-b-2 active:border-white transition-all font-medium"
            onClick={openMenu}
          >
            Menu
          </button>
        </div>
        <div
          ref={dropdownRef}
          className={`absolute cursor-pointer left-0 right-0 z-30 shadow-md bg-app-gold ${
            navigation?.[dropdownOpen - 1]?.dropdownNav?.length > 0
              ? "block"
              : "hidden"
          } transition-all`}
        >
          <ul className="app-main-container py-4 lg:py-6 grid grid-cols-3 gap-4 text-white">
            {navigation?.[dropdownOpen - 1]?.dropdownNav?.map((nav) => (
              <li key={nav?.id} className="cursor-pointer">
                <a
                  className="hover:border-b hover:border-gold transition-all duration-300 cursor-pointer"
                  href={nav.href}
                >
                  {nav.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Navbar;
