import SearchIcon from "assets/icons/SearchIcon";
import React, { useState } from "react";

import { navigation } from "utils/constants";

function Menu({ isOpen, onClose }) {
  const [isOpenDropdown, setIsOpenDropdown] = useState(null);

  const handleOpenDropdown = (e, navId, haveDropdown) => {
    if (haveDropdown) {
      e.preventDefault();
      if (isOpenDropdown) {
        setIsOpenDropdown(null);
      } else {
        setIsOpenDropdown(navId || null);
      }
    }
  };

  return (
    <div
      className={`sm:hidden fixed top-0 bottom-0 left-0 right-0 bg-white/50 backdrop-blur-lg ${
        isOpen ? "inline-block" : "hidden"
      } z-50`}
    >
      <div className="app-nav-container h-full flex flex-col gap-8">
        <div className="text-right font-medium">
          <button
            className="text-sm active:border-b-2 active:border-black transition-all"
            onClick={onClose}
          >
            Close
          </button>
        </div>
        <div className={`flex flex-col justify-between flex-1`}>
          <ul className="flex flex-col gap-2 font-medium">
            {navigation &&
              navigation.map((nav, idx) => {
                const navDropdown = nav && nav.dropdownNav;
                const navPath = nav && nav.href;
                const navLabel = nav && nav.label;
                const navId = nav && nav.id;
                const openDropdown = isOpenDropdown == navId;
                const haveDropdown = navDropdown && navDropdown.length > 0;
                return (
                  <li className="app-animation-translate-start" key={idx}>
                    <a
                      href={navPath}
                      onClick={(e) =>
                        handleOpenDropdown(e, navId, haveDropdown)
                      }
                    >
                      {navLabel}
                    </a>
                    {navDropdown && navDropdown.length > 0 && openDropdown && (
                      <ul className="flex flex-col gap-2 font-medium py-2 px-4">
                        {navDropdown &&
                          navDropdown.map((dropdown) => {
                            const dropdownPath = dropdown && dropdown.href;
                            const dropdownLabel = dropdown && dropdown.label;
                            return (
                              <li>
                                <a href={dropdownPath}>{dropdownLabel}</a>
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Menu;
