import React from 'react'

// Components
import NextIcon from 'assets/icons/NextIcon';
import PreviousIcon from 'assets/icons/PreviousIcon';

function Pagination({
    numberOfPages,
    currentPage,
    setCurrentPage,
    previousPage,
    nextPage
}) {
    const pageNumbers = [...Array(numberOfPages + 1).keys()].slice(1);

    return (
        <nav className='m-5 p-3'>
            <ul className='flex flex-row justify-center gap-5'>
                <li
                    hidden={currentPage == 1}
                    className='py-1 px-2 cursor-pointer'
                >
                    <a
                        onClick={previousPage}
                    >
                        <PreviousIcon />
                    </a>
                </li>
                {pageNumbers.map(pageNumber => (
                    <li
                        key={pageNumber}
                        className={`${currentPage == pageNumber ? 'py-1 px-2 bg-black text-white rounded-lg font-bold cursor-pointer' : 'py-1 px-2 cursor-pointer'}`}
                    >
                        <a
                            onClick={() => setCurrentPage(pageNumber)}
                        >
                            {pageNumber}
                        </a>
                    </li>
                ))}
                <li
                    hidden={currentPage == numberOfPages}
                    className='py-1 px-2 cursor-pointer'
                >
                    <a
                        onClick={nextPage}
                    >
                        <NextIcon />
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination 