module.exports = {
  PORT: "3123",
  NODE_ENV: "development",

  SERVER_DEV_URL: "http://localhost:3456",
  SERVER_STAGING_URL: "https://isaac.cloudxier.com",
  SERVER_PROD_URL: "https://isaac.materdei.school",

  CLIENT_DEV_HOST: "localhost",
  CLIENT_STAGING_HOST: "materdei.cloudxier.com",
  CLIENT_PROD_HOST: "materdei.school",

  CLIENT_DEV_URL: "http://localhost:3000",
  CLIENT_STAGING_URL: "https://materdei.cloudxier.com",
  CLIENT_PROD_URL: "https://materdei.school",

  MAIN_WEBSITE_TITLE: 'Materdei | ',
  SITE_NAME: 'Sekolah Materdei'
}