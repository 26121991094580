import axios from "axios";
import { API_BASE_URL } from "utils/constants/api";

const baseURL = API_BASE_URL;

const api = axios.create({
  baseURL,
  withCredentials: true,
});

export default api;

export const GET_ALL_PUBLIC_POSTS = {
  method: "get",
  url: "/api/blog/v1/blogs/public",
};

export const GET_POST_DETAILS = {
  method: "get",
  url: "/api/blog/v1/blogs/:id",
};

export const SEND_CONTACT_FORM = {
  method: "post",
  url: "/api/blog/v1/email/contact-us",
};

export const getAllRecentEvents = () => {
  return api.get("/api/blog/v1/recent-events");
};

export const getAllUpdatedArticles = () => {
  return api.get("/api/blog/v1/updated-articles");
};

export const getRecentEventDetail = (recent_event_id) => {
  return api.get(`/api/blog/v1/recent-events/${recent_event_id}`);
};

export const getUpdatedArticleDetail = (updated_article_id) => {
  return api.get(`/api/blog/v1/recent-events/${updated_article_id}`);
};
