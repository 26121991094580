import React from "react";

import { structure } from "utils/constants/data";
import landingPageImage3 from "assets/images/landing-page-3.jpeg";
import ImageGallery from "react-image-gallery";

function StrukturOrganisasi() {
  return (
    <div>
      <section>
        <div className="relative">
          <img
            src={landingPageImage3}
            alt="main"
            className="w-full max-h-40 sm:max-h-60 object-cover object-bottom object-center"
          />
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white">
            {/* <div className="app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8">
              <p className="text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start">
                Company foundation is one of the most important
              </p>
            </div> */}
          </div>
        </div>
      </section>

      <section className="bg-app-grey">
        <div className="app-main-container space-y-4">
          <p className="text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start">
            Struktur Organisasi
          </p>
          {/* <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{about.whyUs}</div> */}
          <div>
            <ImageGallery
              items={structure.organization_structure_img}
              showThumbnails={false}
              showPlayButton={false}
            />
          </div>
        </div>
      </section>

      {/* <section className="bg-app-grey">
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            Group Structure
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{about.whyUs}</div>
        </div>
      </section> */}

      {/* <Newsletter /> */}
    </div>
  );
}

export default StrukturOrganisasi;
