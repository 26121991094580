import React from "react";
import moment from "moment";

import TwitterIcon from "assets/icons/social-media/TwitterIcon";
import FacebookIcon from "assets/icons/social-media/FacebookIcon";
import InstagramIcon from "assets/icons/social-media/InstagramIcon";
import LinkedinIcon from "assets/icons/social-media/LinkedinIcon";
import YoutubeIcon from "assets/icons/social-media/YoutubeIcon";

function Footer() {
  return (
    <div className="bg-app-gold text-white">
      <div className="app-nav-container flex flex-col sm:flex-row justify-between gap-6 sm:gap-0">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-12 text-sm sm:text-base">
          <ul className="space-y-2">
            <li>
              <a href="/kata-sambutan">Kata Sambutan</a>
            </li>
            <li>
              <a href="/mengenai-yayasan-dan-sejarahnya">
                Mengenai Yayasan dan Sejarahnya
              </a>
            </li>
            <li>
              <a href="/daftar-lembaga-pendidikan-naungan-yayasan">
                Daftar Lembaga Pendidikan Naungan Yayasan
              </a>
            </li>
            <li>
              <a href="/mengenai-santo-pelindung">Mengenai Santo Pelindung</a>
            </li>
            <li>
              <a href="/struktur-organisasi">Struktur Organisasi</a>
            </li>
          </ul>
          <ul className="space-y-2">
            <li>
              <a href="/terkini-dari-yayasan">Terkini dari Yayasan</a>
            </li>
            <li>
              <a href="/media">Media</a>
            </li>
          </ul>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p className="font-normal uppercase text-base sm:text-lg">
            Follow Us
          </p>
          <div className="flex gap-2 sm:gap-4">
            <a href="/">
              <TwitterIcon className="fill-white h-4 sm:h-6 w-4 sm:w-6" />
            </a>
            <a href="/">
              <FacebookIcon className="fill-white h-4 sm:h-6 w-4 sm:w-6" />
            </a>
            <a href="/">
              <InstagramIcon className="fill-white h-4 sm:h-6 w-4 sm:w-6" />
            </a>
            <a href="/">
              <LinkedinIcon className="fill-white h-4 sm:h-6 w-4 sm:w-6" />
            </a>
            <a href="/">
              <YoutubeIcon className="fill-white h-4 sm:h-6 w-4 sm:w-6" />
            </a>
          </div>
        </div>
      </div>
      <div className="app-nav-container flex flex-col sm:flex-row justify-end items-center sm:items-end gap-6 sm:gap-0">
        <div className="text-[10px] sm:text-sm">
          <p>
            {moment().year()} Yayasan-TK SD SMP SMA Katolik Mater Dei Pamulang.
            All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
