import React, { useEffect, useState } from "react";

import moment from "moment";

import Pagination from "components/layout/Pagination";

import ArrowRightIcon from "assets/icons/ArrowRightIcon";
import landingPageImage from "assets/images/landing-page.jpeg";
import landingPageImage2 from "assets/images/landing-page-2.jpg";
import informasiTerkini1 from "assets/images/informasi-terkini-1.jpeg";
import informasiTerkini2 from "assets/images/informasi-terkini-2.jpeg";
import informasiTerkini3 from "assets/images/informasi-terkini-3.jpeg";
import informasiTerkini4 from "assets/images/informasi-terkini-4.jpeg";
import artikel1 from "assets/images/artikel-1.jpeg";
import artikel2 from "assets/images/artikel-2.jpeg";
import artikel3 from "assets/images/artikel-3.jpeg";
import artikel4 from "assets/images/artikel-4.jpeg";

import { digitalMarketing, home } from "utils/constants/data";
import { getAllRecentEvents, getAllUpdatedArticles } from "api";
// import useFetch from "utils/hooks/useFetch";
// import { GET_ALL_PUBLIC_POSTS } from "api";
// import { esportsConsulting } from "utils/constants/data";

function Home() {
  // const { data: publicPosts, fetch: getAllPublicPosts } =
  //   useFetch(GET_ALL_PUBLIC_POSTS);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(3);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const [recentEvents, setRecentEvents] = useState([]);
  const [updatedArticles, setUpdatedArticles] = useState([]);

  const currentRecordsRecentEvents =
    recentEvents && recentEvents.length > 0
      ? recentEvents?.slice(indexOfFirstRecord, indexOfLastRecord)
      : []
  const numberOfPagesRecentEvents = Math.ceil(
    recentEvents && recentEvents.length > 0
      ? recentEvents.length / recordsPerPage
      : []
  );

  const currentRecordsArtikelBaru =
    updatedArticles && updatedArticles.length > 0
      ? updatedArticles?.slice(indexOfFirstRecord, indexOfLastRecord)
      : []
  const numberOfPagesArtikelBaru = Math.ceil(
    updatedArticles && updatedArticles.length > 0
      ? updatedArticles.length / recordsPerPage
      : []
  );

  const goToNextPage = () => {
    if (currentPage !== numberOfPagesRecentEvents)
      setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  const fetchAllRecentEvents = async () => {
    const result = await getAllRecentEvents();
    const { data } = result || {};
    if (data && Array.isArray(data) && data.length > 0) {
      setRecentEvents(data);
    }
  };

  const fetchAllUpdatedArticles = async () => {
    const result = await getAllUpdatedArticles();
    const { data } = result || {};
    if (data && Array.isArray(data) && data.length > 0) {
      setUpdatedArticles(data);
    }
  };

  useEffect(() => {
    fetchAllRecentEvents();
    fetchAllUpdatedArticles();
  }, []);

  // useEffect(() => {
  //   getAllPublicPosts();
  // }, [getAllPublicPosts]);

  return (
    <div>
      <section>
        <div className="relative">
          <div>
            <img
              src={landingPageImage}
              alt="landing page image 1"
              className="max-h-[60vh] w-full object-cover object-bottom"
            />
          </div>
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white">
            <div className="app-main-container flex flex-col justify-center items-start h-full gap-4 sm:gap-8">
              <p className="text-lg sm:text-3xl md:text-4xl max-w-md font-semibold sm:font-bold app-animation-translate-start">
                Deskripsi sekilas mengenai Yayasan
              </p>
              <button className="text-sm sm:text-base flex items-center gap-2 app-animation-translate-start">
                <p>Learn more</p>
                <ArrowRightIcon className="h-3 sm:h-4 w-3 sm:w-4 fill-white" />
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="grid grid-cols-1 sm:grid-cols-2">
        <div>
          <img
            src={landingPageImage2}
            alt="landing-page-image-2"
            className="w-full h-full aspect-video object-cover object-top app-animation-translate-right-start"
          />
        </div>
        <div className="bg-app-gold text-white px-4 py-6 md:py-12 md:px-5 lg:px-20 flex items-center justify-center sm:justify-start">
          <div>
            <p className="text-lg sm:text-xl md:text-2xl font-semibold sm:font-bold max-w-base text-center sm:text-left app-animation-translate-start">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <p className="text-xs sm:text-sm md:text-base max-w-sm py-3 app-animation-translate-start">
              Lorem ipsum dolor sit amet consectetur.Id magna convallis diam
              condimentum felis convallis.Sed faucibus sagittis a sed commodo
              fermentum massa.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-app-white">
        <div className="app-main-container space-y-8">
          <p className="text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start">
            Deskripsi sekilas mengenai Yayasan
          </p>
          <div className="text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto">
            {home.description}
          </div>
        </div>
      </section>

      <section className="bg-app-grey">
        <div className="app-main-container space-y-8">
          <p className="text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start">
            Informasi terkini
          </p>
          {
            currentRecordsRecentEvents && 
            currentRecordsRecentEvents.length > 0 &&
              (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-6 app-animation-translate-start px-10 md:px-0 sm:px-0">
                  {
                    currentRecordsRecentEvents?.map((post, i) => (
                      <a
                        key={i}
                        className="space-y-1 sm:space-y-2"
                        href={`/informasi-terkini/${post.id}`}
                      >
                        <img
                          className="w-full aspect-[4/3] object-cover border"
                          src={post.img_cover}
                          alt={post.title}
                        />
                        <p className="text-lg sm:text-base font-bold">{post.title}</p>
                        <p className="text-sm sm:text-sm truncate">{post.summary}</p>
                        <p className="text-sm sm:text-sm text-app-grey-2">
                          {moment(post.createdAt).format("DD MMMM YYYY")}
                        </p>
                      </a>
                    )) || 'Belum ada informasi terbaru'
                  }
                </div>
              )
            ||
              (
                <div className="text-center">
                  Belum ada informasi terbaru
                </div>
              )

          }
          {
            currentRecordsRecentEvents &&
            Array.isArray(currentRecordsRecentEvents) &&
            currentRecordsRecentEvents.length > 3 && (
              <div>
                <Pagination
                  numberOfPages={numberOfPagesRecentEvents}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  previousPage={goToPreviousPage}
                  nextPage={goToNextPage}
                />
              </div>
            )
          }
        </div>
      </section>

      <section>
        <div className="app-main-container space-y-8">
          <p className="text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start">
            Artikel terbaru
          </p>
          {
            currentRecordsArtikelBaru &&
            currentRecordsArtikelBaru.length > 0 &&
              (
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {
                    currentRecordsArtikelBaru?.map((item, i) => (
                      <a
                        href={`/artikel-terbaru/${item?.id}`}
                        key={i}
                        className="flex flex-col items-center gap-2"
                      >
                        <img
                          className="w-full aspect-[4/3] object-cover border"
                          src={item.img_cover}
                          alt={item.title}
                        />
                        <p className="text-lg sm:text-base text-center text-app-blue-3 font-semibold">
                          {item.title}
                        </p>
                      </a>
                    ))
                  }
                </div>
              )
            ||
              (
                <div className="text-center">
                  Belum ada artikel terbaru
                </div>
              )
          }
          {
            currentRecordsArtikelBaru &&
            Array.isArray(currentRecordsArtikelBaru) &&
            currentRecordsArtikelBaru.length > 4 && (
              <div>
                <Pagination
                  numberOfPages={numberOfPagesArtikelBaru}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  previousPage={goToPreviousPage}
                  nextPage={goToNextPage}
                />
              </div>
            )
          }
        </div>
      </section>

      <section className="bg-app-grey">
        <div className="app-main-container space-y-4">
          <p className="text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start">
            Galeri media terkini
          </p>
          <div className="text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto">
            {digitalMarketing.marketingCatalogue}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
